import React from 'react';
import PropTypes from 'prop-types';
import ResultsView from 'ToolboxComponents/webapp/pages/results-view/results-view';
import Text from 'ToolboxComponents/commons/texts/text/text';


const CircuitView = ({ addFavorite, circuit, deleteFavorite, stops }) => {
  console.log('circuit', circuit);
  return (
    <ResultsView
      addFavorite={event => addFavorite(event)}
      circuit={circuit}
      deleteFavorite={event => deleteFavorite(event)}
      nbProductsPage={circuit.itinerary ? 1000 : undefined}
      hasFilters={false}
      specialHeader= {{
        title: circuit.title,
        teaser: circuit.teaser,
        highlight: <Text path={`page.majors.enjoy`} />
      }}
      products={stops}
    />
  );
};

CircuitView.propTypes = {
  addFavorite: PropTypes.func.isRequired,
  circuit: PropTypes.object.isRequired,
  deleteFavorite: PropTypes.func.isRequired,
  stops: PropTypes.array.isRequired,
};

export default CircuitView;
