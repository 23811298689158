import React, { useContext, useEffect, useState  } from 'react';
import { AccountContext, GeolocationContext, LanguageContext, WebappContext, WhiteLabelContext } from "ToolboxUtils/web/context/context";
import { Helmet } from "react-helmet";
import CircuitView from 'ToolboxComponents/webapp/pages/circuit-view/circuit-view';
import apiChuchoteurs from 'ToolboxUtils/web/api/api-chuchoteurs';
import Loader from 'ToolboxComponents/commons/loader/loader';
import getMetaDescription from 'ToolboxUtils/web/get-meta-description';
import useIsContentLoaded from 'ToolboxUtils/web/hooks/use-is-content-loaded';
import slugify from 'url-slug';


const Circuit = props => {
  const [account, setAccount] = useContext(AccountContext).usePath();
  const [geolocation] = useContext(GeolocationContext).usePath();
  const [language] = useContext(LanguageContext).usePath();
  const [signIn, setSignIn] = useContext(WebappContext).usePath('modals.signIn');
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();

  const [circuit, setCircuit] = useState({});
  const [stops, setStops] = useState([]);

  useEffect(() => {
    if (whiteLabel && whiteLabel.id) {
      async function getCircuit() {
        try {
          const circuit = await apiChuchoteurs.get(`/products/${props.match.params.id}`, {
            params: { filter: 'circuit', language: language, whiteLabelId: whiteLabel.id }
          });
          setCircuit(circuit);
        } catch (err) {
          console.log(err);
        }
      }
      getCircuit();
    }
    if (whiteLabel && whiteLabel.id && geolocation && geolocation.coordinates) {
      async function getStops() {
        try {
          const stops = await apiChuchoteurs.get(`/products/${props.match.params.id}`, {
            params: {
              filter: 'stops',
              language: language,
              latitude: geolocation.coordinates.latitude,
              longitude: geolocation.coordinates.longitude,
              whiteLabelId: whiteLabel.id
            }
          });
          setStops(stops);
        } catch (err) {
          console.log(err);
        }
      }
      getStops();
    }
  }, [whiteLabel]);

  const openSignInModal = status => {
    setSignIn({ opened: true });
  }

  const updateFav = id => {
    if (account.favorites.includes(id)) {
      const favorites = account.favorites.filter(favorite => favorite !== id);
      setAccount('favorites', favorites);
    } else {
      const favorites = account.favorites.concat([id]);
      setAccount('favorites', favorites);
    }
  }

  useIsContentLoaded(stops.length > 0);
  return (
    <>
      {circuit.hasOwnProperty('id') && stops.length > 0 ?
        <>
          <Helmet>
            <title>{circuit.title} - {whiteLabel.name}</title>
            <meta name="description" content={getMetaDescription(circuit.teaser)}/>
            <link rel="canonical" href={'https://' + window.location.hostname + (whiteLabel.path ? '/' + whiteLabel.path : '') + props.page.path[language] + `/${circuit.id}/${slugify(circuit.title, {lower: true})}`} />
          </Helmet>
        <CircuitView
          addFavorite={async (id) => {
            if (account && account.id) {
              try {
                const favorite = await apiChuchoteurs.post(`/accounts/${account.id}/products/${id}/favorites`, {
                  token: localStorage.token
                })
                updateFav(id);
              } catch(err) {
                console.log(err);
              }
            } else {
              openSignInModal(true);
            }
          }}
          circuit={circuit}
          deleteFavorite={async (id) => {
            if (account && account.id) {
              try {
                const favorite = await apiChuchoteurs.delete(`/accounts/${account.id}/products/${id}/favorites`, {
                  token: localStorage.token
                })
                updateFav(id);
              } catch(err) {
                console.log(err);
              }
            } else {
              openSignInModal(true);
            }
          }}
          stops={stops}
        />
        </>
        : <Loader />
      }
    </>
  );
}

export default Circuit;
